<template>
  <div class="container max-w-md px-4 pt-4 mx-auto">
    <loader z-index="z-50" v-if="loading || uploadProgress != 0" />

    <div class="fixed top-0 left-0 z-50 w-1/2 transition-all duration-100 transform shadow h-2px bg-primary-400" :style="{'width': uploadProgress + '%'}"></div>
    
    <transition appear mode="out-in" enter-class="ease-out transform -translate-y-1/2 _w-8/12 _-translate-x-1/2 h-120 _left-1/2" enter-active-class="transition-all duration-500">
      <img v-if="image || imageUrl" :src="imageUrl" class="absolute inset-x-0 object-cover object-center w-full h-48 opacity-75 top-16">
    </transition>
    <div :class="['flex flex-col transition-all duration-500 transform', {'mt-48': image || imageUrl}]">
      <div class="flex items-center justify-between">
        <div :class="['relative w-full transition-all duration-100 transform border-b border-gray-600', titleFocus || title ? 'bg-gray-700 rounded-md' : 'bg-transparent']">
          <svg class="absolute left-0 w-4 h-4 ml-2 text-gray-400 transform -translate-y-1/2 top-1/2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
          <input v-model="title" @focus="titleFocus = true" @blur="titleFocus = false" type="text" class="w-full py-2 pl-8 text-white placeholder-gray-400 bg-transparent focus:outline-none" placeholder="Name des Rezeptes" >      
        </div>
        <button @click="$refs.imageFile.click()" class="flex items-center justify-center p-2 ml-4 text-gray-400 rounded-full hover:bg-gray-700 hover:text-gray-200 focus:outline-none">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
        </button>
      </div>
      <input @change="updateImage" type="file" ref="imageFile" class="hidden" accept="image/*">
      <div class="container flex flex-col max-w-md pt-8 mx-auto">
        <div class="flex items-center justify-between">
          <div :class="['relative w-40 transition-all duration-100 transform border-b border-gray-600', timeFocus ? 'bg-gray-700 rounded-md' : 'bg-transparent']">
            <svg class="absolute left-0 w-4 h-4 ml-2 text-gray-400 transform -translate-y-1/2 top-1/2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            <input v-model="time" @focus="timeFocus = true" @blur="timeFocus = false" type="text" class="w-full py-1 pl-8 text-white placeholder-gray-400 bg-transparent focus:outline-none" placeholder="Zeitaufwand" >      
          </div>
          <div class="flex items-center justify-end w-1/2">
            <button @click="level = n" :class="['p-2 rounded-full focus:outline-none', n <= level ? 'text-primary-400' : 'text-gray-400 hover:bg-gray-700 hover:text-gray-200']" v-for="n in 5" :key="n">
              <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"></path></svg>
            </button>
          </div>
        </div>
      </div>

      <div :class="['relative flex flex-wrap items-center w-full mt-8 transition-all duration-100 transform border-b border-gray-600', categorySearchFocus || categorySearch ? 'bg-gray-700 rounded-md' : 'bg-transparent']">
        <svg class="w-4 h-4 mx-2 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"></path></svg>
        <span @click="categories.splice(n, 1)" class="flex items-center justify-center px-2 mr-2 text-sm rounded-full bg-primary-600 py-2px" v-for="(category, n) in categories" :key="category.id">
          {{category.title}}
          <svg class="w-3 h-3 ml-1 opacity-75" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
        </span>
        <input :value="categorySearch" @input="e => categorySearch = e.target.value" @keyup.enter="$store.dispatch('recipes/addCategory', categorySearch)" @focus="categorySearchFocus = true" @blur="categorySearchFocus = false" type="text" class="flex-1 py-1 pr-2 text-white placeholder-gray-400 bg-transparent focus:outline-none" placeholder="Kategorie auswählen" >      
        <ul :class="['absolute inset-x-0 z-30 pb-1 px-1 pt-2 flex flex-wrap transition-all duration-200 transform origin-top bg-gray-700 rounded-b-md top-7', categorySearch ? 'scale-x-100 scale-y-100 opacity-100' : 'scale-x-90 scale-y-0 h-0 opacity-0']">
          <li @click="categories.push(category); categorySearch = ''" class="flex items-center px-2 mr-2 rounded-full cursor-pointer py-2px bg-primary-600 hover:bg-primary-600" v-for="category in categorySearchResults" :key="category.id">
            {{category.title}}
          </li>
          <li @click="$store.dispatch('recipes/addCategory', categorySearch)" class="flex items-center px-2 rounded-full cursor-pointer py-2px bg-primary-600 hover:bg-primary-600" v-if="!categorySearchResults.length">
            <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
            {{categorySearch}}
          </li>
        </ul>
      </div>

      <div class="flex items-center justify-between mt-8 mb-2">
        <h2 class="text-xl font-semibold tracking-wider text-white opacity-50">Zutaten</h2>
        <button @click="addIngredient()" class="px-2 py-1 text-xs text-gray-200 uppercase bg-gray-700 rounded-md focus:outline-none">
          Hinzufügen
        </button>
      </div>
      <div class="flex items-center mb-1">
        <div class="w-1/5">
          <input v-model="newIngredient.qty" type="text" :class="['w-full py-1 text-white placeholder-gray-400 transition-all duration-100 transform bg-transparent border-b border-gray-600 focus:outline-none', newIngredient.qty ? 'rounded-md px-2 bg-gray-700' : 'focus:rounded-md focus:px-2 focus:bg-gray-700']" placeholder="Menge">
        </div>
        <div class="w-4/5 pl-4">
          <input v-model="newIngredient.title" @keyup.enter="addIngredient()" type="text" :class="['w-full py-1 text-white placeholder-gray-400 transition-all duration-100 transform bg-transparent border-b border-gray-600 focus:outline-none', newIngredient.title ? 'rounded-md px-2 bg-gray-700' : 'focus:rounded-md focus:px-2 focus:bg-gray-700']" placeholder="Zutat">
        </div>
      </div>
      <div class="flex items-center mb-1" v-for="(ingredient, n) in ingredients" :key="`ingredient-${n}`">
        <div class="w-1/5">
          <span class="">{{ingredient.qty}}</span>
        </div>
        <div class="inline-flex items-center justify-between w-4/5 pl-4">
          <span class="">{{ingredient.title}}</span>
          <button @click="ingredients.splice(n, 1)" class="p-1 text-xs text-gray-200 uppercase bg-gray-700 bg-opacity-50 rounded-md focus:outline-none">
            <svg class="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
          </button>
        </div>
      </div>
      <div class="mt-8 mb-2">
        <h2 class="text-xl font-semibold tracking-wider text-white opacity-50">Vorbereitung</h2>
      </div>
      <textarea v-model="preparation" class="py-1 transition-all duration-100 transform bg-transparent border-b border-gray-600 focus:outline-none focus:bg-gray-700 focus:rounded-md focus:px-2" rows="5"></textarea>
      <div class="mt-8 mb-2">
        <h2 class="text-xl font-semibold tracking-wider text-white opacity-50">Zubereitung</h2>
      </div>
      <textarea v-model="instructions" class="py-1 transition-all duration-100 transform bg-transparent border-b border-gray-600 focus:outline-none focus:bg-gray-700 focus:rounded-md focus:px-2" rows="8"></textarea>
      <div class="flex items-center justify-between w-full mt-8">
        <button @click="createRecipe()" :class="['flex-1 flex items-center justify-center py-2 rounded-md focus:outline-none', !title || !instructions ? 'bg-gray-900 opacity-50 text-gray-200' : 'text-white bg-primary-600 hover:bg-primary-500']">
          <svg class="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path></svg>
          Speichern
        </button>
        <button @click="remove = true" class="px-4 py-2 ml-4 bg-red-600 rounded-md focus:outline-none" v-if="$route.path.includes('recipes/edit')">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
        </button>
      </div>
    </div>

    <modal :open="remove" @close="remove = false" :closeable="false">
      <h2 class="text-lg">Möchtest du das Rezept für {{title}} wirklich löschen?</h2>
      <div class="flex items-center justify-end mt-8">
        <button @click="remove = false" class="flex-1 px-4 py-2 mr-4 text-sm bg-gray-700 rounded-md sm:flex-initial focus:outline-none">Abbrechen</button>
        <button @click="deleteRecipe()" class="flex-1 px-4 py-2 text-sm bg-red-600 rounded-md sm:flex-initial focus:outline-none">Ja, löschen</button>
      </div>
    </modal>
  </div>
</template>

<script>
import loader from '@/components/loader'
import modal from '@/components/modal'

export default {
  components: { loader, modal },
  data: () => ({
    loading: false,
    title: '',
    titleFocus: false,
              categorySearch: '',
              categorySearchFocus: false,
              categories: [],
    ingredients: [],
    newIngredient: {
      title: '',
      qty: ''
    },
    image: null,
    imageUrl: '',
    preparation: '',
    instructions: '',
    level: 2,
    time: '',
    timeFocus: false,
    newID: null,
    remove: false
  }),
  computed: {
    uploadProgress: vm => vm.$store.getters['recipes/upload'],
    categorySearchResults: vm => vm.$store.getters['recipes/categories'].filter(category => category.title.toLowerCase().match(vm.categorySearch.toLowerCase()) && !vm.categories.map(category => category.title).includes(category.title))
  },
  watch: {
    uploadProgress(nVal, old) {
      if(nVal == 100) {  
        this.loading = false
        this.$store.commit('recipes/setUpload', 0)
        setTimeout(() => { this.$router.push(`/recipes/${this.$route.path.includes('/recipes/edit') ? this.$route.params.id : this.newID}`) }, 500)
      }
    }
  },
  methods: {
    addIngredient() {
      if(!this.newIngredient.title) return
      this.ingredients.push(this.newIngredient)
      this.newIngredient = {
        title: '',
        qty: ''
      }
    },
    updateImage(e) {
      const files = e.target.files
      let filename = files[0].name
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.imageUrl = fileReader.result
      })
      fileReader.readAsDataURL(files[0])
      this.image = files[0]
    },
    async createRecipe() {
      if(!this.title || !this.ingredients.length || !this.instructions) return
      if(this.$route.path.includes('/recipes/edit') && this.$route.params.id) {
        this.loading = true
        let data = {
          id: this.$route.params.id,
          title: this.title,
          ingredients: this.ingredients,
          preparation: this.preparation,
          instructions: this.instructions,
          time: this.time,
          level: this.level,
          categories: this.categories
        }
        if(this.image) {
          data.image = this.image
        }
        await this.$store.dispatch('recipes/updateRecipe', data)
      } else {
        this.loading = true
        const data = {
          title: this.title,
          ingredients: this.ingredients,
          preparation: this.preparation,
          instructions: this.instructions,
          time: this.time,
          level: this.level,
          categories: this.categories
        }
        const newId = await this.$store.dispatch('recipes/addRecipe', { data: data, image: this.image })
        this.newID = newId
      }
    },
    async deleteRecipe() {
      this.remove = false
      this.loading = true
      this.$store.dispatch('recipes/delete', this.$route.params.id).then(() => {
        this.$router.replace('/recipes')
      })
    }
  },
  created() {
    if(this.$route.path.includes('/recipes/edit') && this.$route.params.id) {
      const original = this.$store.getters['recipes/single'](this.$route.params.id)
      this.title = original.title
      this.time = original.time
      this.level = original.level
      this.ingredients = original.ingredients
      this.preparation = original.preparation
      this.imageUrl = original.imageUrl
      this.instructions = original.instructions
      this.categories = original.categories || []
    }
  }
}
</script>
